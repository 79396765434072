import { useEffect, useState } from 'react'
import { Input, Button, Text } from '@chakra-ui/react'
import { ArrowForwardIcon } from '@chakra-ui/icons'
import Navbar from '../components/Navbar'
import axios from 'axios'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'

export default function Component() {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const navigate = useNavigate()

  useEffect(() => {
    const token = Cookies.get('token') // Retrieve the token inside useEffect
    if (token) {
      navigate('/dashboard') // Use navigate for redirection
    }
  }, [navigate]) // Only re-run if navigate changes

  const handleLogin = async () => {
    try {
      const response = await axios.post(
        'https://vmpayments-backend.school4dev.org/login',
        {
          username,
          password,
        },
      )
      const data = response.data
      if (data && data.accessToken) {
        Cookies.set('token', data.accessToken, { expires: 1 }) // Save token with a 1-day expiration
        window.location.href = '/dashboard' // Redirect to dashboard or other page after login
      } else {
        setError('Login failed. Please check your username and password.')
      }
    } catch (error) {
      setError('Login failed. Please check your username and password.')
    }
  }

  return (
    <>
      <Navbar />
      <div className="flex flex-col md:flex-row h-screen">
        <div className="flex-1 bg-[#F2F0EC] flex flex-col justify-center items-center p-4 md:p-8">
          <img
            src="/signup.png"
            alt="Mother and child"
            className="w-full max-w-md"
          />
          <h1 className="text-[#A2A2A2] text-2xl md:text-4xl font-bold mt-4 max-w-md text-center">
            Together for a better tomorrow
          </h1>
        </div>
        <div className="flex-1 flex flex-col justify-center items-center bg-white p-4 md:p-8">
          <div className="w-full max-w-md">
            <h2 className="text-2xl md:text-3xl font-bold mb-6 text-[#A2A2A2] text-center">
              Login
            </h2>
            {error && (
              <Text color="red.500" mb={4} textAlign="center">
                {error}
              </Text>
            )}
            <div className="space-y-4">
              <div>
                <Text
                  htmlFor="username"
                  className="text-[#E03987] text-md font-bold my-2"
                >
                  User Name
                </Text>
                <Input
                  id="username"
                  type="text"
                  placeholder="Enter User Name"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div>
                <Text
                  htmlFor="password"
                  className="text-[#E03987] text-md font-bold my-2"
                >
                  Password
                </Text>
                <Input
                  id="password"
                  type="password"
                  placeholder="Enter Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              {/* <div className="flex justify-end">
                <Button
                  variant="link"
                  color={'#525252'}
                  className="mb-6 text-right text-[#525252] underline"
                >
                  Change Credentials
                </Button>
              </div> */}
              <Button
                bgColor={'#E03987'}
                color="white"
                className="w-full text-left"
                rightIcon={<ArrowForwardIcon />}
                onClick={handleLogin}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
