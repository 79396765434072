import { Box, Flex, Avatar, Link } from '@chakra-ui/react'
import { ArrowRightOnRectangleIcon } from '@heroicons/react/24/solid'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'

const Navbar = () => {
  const navigate = useNavigate()
  const [token, setToken] = useState(Cookies.get('token'))

  const handleLogout = () => {
    Cookies.remove('token') // Remove the token
    setToken(null) // Update state to reflect logout
    navigate('/') // Redirect to the signup or login page
  }

  // Update token state if it changes (e.g., after logout)
  useEffect(() => {
    setToken(Cookies.get('token'))
  }, [])

  return (
    <Box
      as="nav"
      className="bg-[#F2F0EC] shadow-sm border-b-black border-[1.5px]"
      py={2}
    >
      <Flex maxW="8xl" mx="auto" px={4} align="center" justify="space-between">
        <Flex align="center">
          <img src="/school_logo.png" className="w-36" alt="School Logo" />
        </Flex>
        {token && (
          <Flex align="center">
            <Avatar size="sm" name="Admin" src="path-to-avatar-image" />
            <Link mx={4} href="#" className="text-pink-500">
              Admin
            </Link>
            <ArrowRightOnRectangleIcon
              className="h-6 w-6 text-[#E03987] ml-2 cursor-pointer"
              onClick={handleLogout} // Attach the logout handler
            />
          </Flex>
        )}
      </Flex>
    </Box>
  )
}

export default Navbar
