// index.js
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import './index.css'
// 1. import `ChakraProvider` component
import { ChakraProvider } from '@chakra-ui/react'



const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter>
      <ChakraProvider>
          <App />
      </ChakraProvider>
  </BrowserRouter>,
)
