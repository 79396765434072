// src/htmlUtils.js
export const generateHTML = (
  name,
  address,
  city,
  state,
  pincode,
  country,
  pan,
  date,
  donationId,
  total,
  receiptId,
  phone, email
) => `
<head>
        <style>
        body {
            font-family: Arial, sans-serif;
            margin: 30px;
            padding: 0;
        }
        .receipt-container {
            border: 1px solid #ddd;
            padding: 20px;
            max-width: 700px;
            margin: 0 auto;
        }
        .header {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
        }
        .header img {
            height: 70px;
            margin-right: 20px;
        }
        .header .header-title {
            font-size: 14px;
            font-weight: bold;
        }
        .header .header-title span {
            display: block;
        }
            
        .donation-receipt-title {
            background-color: #87CEEB;
            color: white;
            text-align: center;
            padding: 10px;
            font-weight: bold;
            margin-bottom: 20px;
        }
        .content-section {
            margin-bottom: 20px;
        }
        .content-section table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 10px;
        }
        .content-section table, th, td {
            border: 1px solid #ddd;
        }
        .content-section th, td {
            padding: 8px;
            text-align: left;
            vertical-align: middle; /* Center text vertically */
        }
        .content-section th {
            background-color: #f2f2f2;
            font-weight: bold;
        }
        .disclaimer {
            color: red;
            font-size: 12px;
            margin-top: 20px;
        }
        .footer {
            font-size: 12px;
            border-top: 1px solid #ddd;
            padding-top: 10px;
            margin-top: 20px;
        }
    </style>
</head>
<body>
    <div class="">
        <div class="header">
            <img src="school_logo.png" alt="School Logo">
            <div class="header-title">
                <span>SCHOOL</span>
                <span>Society of Community Health Oriented Operational Links</span>
                <span>(also known as Vriddha Mitra)</span>
            </div>
        </div>
        <div class="donation-receipt-title">DONATION RECEIPT</div>
        <div class="content-section">
            <p>
                <strong>Received with thanks from:</strong><br>
                ${name},<br>
                ${address}<br>
                ${city}, ${state}, ${pincode}<br>
                ${country}<br>
                Mobile: ${phone}<br>
                Email: ${email}<br>
                PAN: ${pan}<br>
            </p>
            <table>
                <tr>
                    <td>Receipt No:</td>
                    <td>${receiptId}</td>
                </tr>
                <tr>
                    <td>Receipt Date:</td>
                    <td>${date?.split('T')[0]}</td>
                </tr>
                <tr>
                    <td>Donation ID:</td>
                    <td>${donationId}</td>
                </tr>
            </table>
        </div>
        <div class="content-section">
            <p>The sum of <strong>Rs. ${total}</strong> received for the following</p>
            <table>
                <tr>
                    <th>Donation Purpose</th>
                    <th>Cost (Rs.)</th>
                    <th>Qty</th>
                    <th>Amount (Rs.)</th>
                </tr>
                <tr>
                    <td>General Fund</td>
                    <td>Rs. ${total}</td>
                    <td>1</td>
                    <td>Rs. ${total}</td>
                </tr>
                <tr>
                    <td colspan="3"><strong>Total</strong></td>
                    <td>Rs. ${total}</td>
                </tr>
            </table>
        </div>
        <div class="content-section">
            <p>This receipt is exempt from revenue stamp vide Exemption in Article 53, Schedule I of the Indian Stamp Act, 1899. Donations to Society of Community Health Oriented Operational Links qualify for deduction u/s 80(G) of the Income Tax Act, 1961 vide certificate of exemption no. <strong>AAPCS7351JF20211</strong> issued on 06-Apr-2022.</p>
            <p class="disclaimer">Please note that this is purely an acknowledgement of donation received. This receipt cannot provide tax benefit. The same will be provided through Form 10BE that will be sent by the end of the financial year. This receipt is invalid in case of non-realization of the money instrument or reversal of the credit card charge or reversal of donation amount for any reason.</p>
        </div>
        <div class="footer">
            Society of Community Health Oriented Operational Links,<br>
            23, Krishna Vihar Colony, Sachin Tendulkar Marg,<br>
            Gwalior 474011<br>
            Madhya Pradesh, India<br>
            PAN #: AAPCS7351J&nbsp;&nbsp;&nbsp;&nbsp;BOG Reg. No.: AAPCS7351JF20211<br>
            Issue Date: 06-Apr-2022&nbsp;&nbsp;&nbsp;&nbsp;Contact: 2929292920 | pat@school4dev.org
        </div>
    </div>
</body>
`
