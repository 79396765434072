import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
  Select,
  HStack,
  Stack,
  Input,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import Navbar from '../components/Navbar';
import { Parser } from '@json2csv/plainjs';
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { generateHTML } from '../htmlUtils';
import { generatePDF, generatePDF2 } from '../pdfUtils';

const Dashboard = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const [totalDonations, setTotalDonations] = useState(0);
  const [totalDonors, setTotalDonors] = useState(0);

  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [paymentMode, setPaymentMode] = useState('All');

  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);

  const [pdfContent, setPdfContent] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const navigate = useNavigate();

  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    }
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = Cookies.get('token');
        const response = await axios.get(
          'https://vmpayments-backend.school4dev.org/donations',
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          },
        );

        const donationsData = response.data;
        setAllData(donationsData);
        setData(donationsData);

        const totalDonationsAmount = donationsData.reduce(
          (total, donation) => total + donation.amount,
          0,
        );
        setTotalDonations(totalDonationsAmount);

        const uniqueDonors = new Set(
          donationsData.map((donation) => donation.donorName),
        );
        setTotalDonors(uniqueDonors.size);
      } catch (error) {
        console.error('Error fetching donations data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    let filteredData = [...allData];

    if (fromDate) {
      filteredData = filteredData.filter(
        (donation) => new Date(donation.date) >= new Date(fromDate),
      );
    }
    if (toDate) {
      filteredData = filteredData.filter(
        (donation) => new Date(donation.date) <= new Date(toDate),
      );
    }
    if (paymentMode && paymentMode !== 'All') {
      filteredData = filteredData.filter(
        (donation) =>
          donation.method?.toLowerCase() === paymentMode?.toLowerCase(),
      );
    }

    setData(filteredData);
    const totalDonationsAmount = filteredData.reduce(
      (total, donation) => total + donation.amount,
      0,
    );
    setTotalDonations(totalDonationsAmount);

    const uniqueDonors = new Set(
      filteredData.map((donation) => donation.donorName),
    );
    setTotalDonors(uniqueDonors.size);
  }, [fromDate, toDate, paymentMode, allData]);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = data.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(data.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleExportCSV = () => {
    // Create a deep copy of the data array
    const modifiedData = data.map(item => ({
      ...item,
      eightyG: 'Yes' // Set the eightyG field to "Yes"
    }));
  
    // Parse the modified data
    const parser = new Parser({});
    const csv = parser.parse(modifiedData);
  
    // Create a blob from the CSV string
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
  
    // If the download attribute is supported
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'donations.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  

  const handleDownloadPDF = async (
    name,
    address,
    city,
    state,
    pincode,
    country,
    pan,
    date,
    donationId,
    total,
    receiptId,
    phone,
    email,
  ) => {
    const html = generateHTML(
      name,
      address,
      city,
      state,
      pincode,
      country,
      pan,
      date,
      donationId,
      total,
      receiptId,
      phone,
      email,
    )
    await generatePDF2(html, 'TaxReceipt_80G.pdf')
  }

  const handleViewPDF = async (
    name,
    address,
    city,
    state,
    pincode,
    country,
    pan,
    date,
    donationId,
    total,
    receiptId,
    phone,
    email,
  ) => {
    const html = generateHTML(
      name,
      address,
      city,
      state,
      pincode,
      country,
      pan,
      date,
      donationId,
      total,
      receiptId,
      phone,
      email,
    );
    const pdfBlob = await generatePDF(html);
    const pdfUrl = URL.createObjectURL(pdfBlob);
    setPdfContent(pdfUrl);
    onOpen();
  };

  return (
    <div className="bg-[#F2F0EC] min-h-screen">
      <Navbar />
      <Box p={5} className="max-w-7xl mx-auto">
        <Stack
          direction={{ base: 'column', md: 'row' }}
          justifyContent="space-between"
          my={8}
          spacing={6}
        >
          <Stack
            direction={{ base: 'column', md: 'row' }}
            spacing={{ base: 4, md: 16 }}
          >
            <Stack>
              <Text className="font-bold text-lg">Total Donation:</Text>
              <Text className="font-bold text-lg text-[#2B3674]">
                {totalDonations}
              </Text>
            </Stack>
            <Stack>
              <Text className="font-bold text-lg">Total Donors:</Text>
              <Text className="font-bold text-lg text-[#2B3674]">
                {totalDonors}
              </Text>
            </Stack>
          </Stack>
          <HStack spacing={4} wrap="wrap">
            <Stack className="w-full sm:w-[9rem]">
              <Text className="text-sm text-[#E03987]">From Date:</Text>
              <Input
                type="date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
                placeholder="From Date"
                className="border border-black"
              />
            </Stack>
            <Stack className="w-full sm:w-[9rem]">
              <Text className="text-sm text-[#E03987]">To Date:</Text>
              <Input
                type="date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                placeholder="To Date"
                className="border border-black"
              />
            </Stack>
            <Stack className="w-full sm:w-[9rem]">
              <Text className="text-sm text-[#E03987]">Mode of Payment:</Text>
              <Select
                value={paymentMode}
                onChange={(e) => setPaymentMode(e.target.value)}
                className="border border-black"
              >
                <option value="All">All</option>
                <option value="UPI">UPI</option>
                <option value="Card">Card</option>
                <option value="Cash">Cash</option>
                <option value="Net Banking">Net Banking</option>
              </Select>
            </Stack>
            <Button
              colorScheme="pink"
              className="w-full sm:w-auto mt-4 sm:mt-0"
              onClick={handleExportCSV}
            >
              Export CSV
            </Button>
          </HStack>
        </Stack>
        <TableContainer className="border border-black mt-8">
          <Table colorScheme="black">
            <Thead>
              <Tr>
                <Th className="bg-[#E03987] text-white border border-black">
                  Receipt ID
                </Th>
                <Th className="bg-[#E03987] text-white border border-black">
                  Date
                </Th>
                <Th className="bg-[#E03987] text-white border border-black">
                  Donor Name
                </Th>
                <Th className="bg-[#E03987] text-white border border-black">
                  Amount
                </Th>
                <Th className="bg-[#E03987] text-white border border-black">
                  Mode
                </Th>
                <Th className="bg-[#E03987] text-white border border-black">
                  Pan No.
                </Th>
                <Th className="bg-[#E03987] text-white border border-black">
                  80G Status
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {currentRows.map((row, index) => (
                <Tr key={index}>
                  <Td className="border border-black">{row.receiptNo}</Td>
                  <Td className="border border-black">
                    {row.date?.split('T')[0]}
                  </Td>
                  <Td className="border border-black">{row.donorName}</Td>
                  <Td className="border border-black">{row.amount}/-</Td>
                  <Td className="border border-black">{row.method}</Td>
                  <Td className="border border-black">{row.panNo}</Td>
                  <Td className="border border-black">
                    <a

                      className="text-blue-500 flex items-center cursor-pointer"
                    >
                      <span onClick={() =>
                        handleViewPDF(
                          row.donorName,
                          row.address,
                          row.city,
                          row.state,
                          row.pincode,
                          row.country,
                          row.panNo,
                          row.date,
                          row.donationId,
                          row.amount,
                          row.receiptNo,
                          row.phone,
                          row.email,
                        )
                      } className="mr-2">View</span>
                      <ArrowDownTrayIcon onClick={()=>handleDownloadPDF(                          row.donorName,
                          row.address,
                          row.city,
                          row.state,
                          row.pincode,
                          row.country,
                          row.panNo,
                          row.date,
                          row.donationId,
                          row.amount,
                          row.receiptNo,
                          row.phone,
                          row.email,)} className="text-[#E03987] w-6 h-6 ml-auto" />
                    </a>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <HStack justifyContent="space-between" mt={4}>
          <Button
            onClick={handlePreviousPage}
            leftIcon={<ChevronLeftIcon />}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <Text>
            Page {currentPage} of {totalPages}
          </Text>
          <Button
            onClick={handleNextPage}
            rightIcon={<ChevronRightIcon />}
            disabled={currentPage === totalPages}
          >
            Next
          </Button>
        </HStack>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Receipt Preview</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {pdfContent && (
              <iframe
                src={pdfContent}
                title="PDF Preview"
                className='w-full h-[80vh]'
              />
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="pink" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Dashboard;
