import html2pdf from 'html2pdf.js';

export const generatePDF = async (htmlContent) => {
  const element = document.createElement('html');
  element.innerHTML = htmlContent;

  const opt = {
    margin: 0.2,
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
  };

  const pdfBlob = await html2pdf().set(opt).from(element).outputPdf('blob');
  return pdfBlob;
};

export const generatePDF2 = (htmlContent, fileName) => {
  const element = document.createElement('html');
  element.innerHTML = htmlContent;

  const opt = {
    margin: 0.2,
    filename: fileName,
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
  };

  html2pdf().set(opt).from(element).save();
};
